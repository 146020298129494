import p1 from "../images/KOT.png";
import p3 from "../images/cdaily.png";
import p2 from "../images/hottakes.png";
import p4 from "../images/club.png";
import p5 from "../images/kow.png";
import p6 from "../images/Polka.png";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const projects = [
  {
    imag: p5,
    link: "https://www.thekingdomofwonders.com/",
    title: "Kingdom of Wonders Circus",
    desc: [
      "• Created a website using JavaScript, React.js, Firebase, and Tailwind CSS.",
      "• Used the square API to create a ticketing system, generating $21000 in revenue.",
    ],
  },
  {
    imag: p6,
    link: "https://www.thekingdomofwonders.com/",
    title: "PolkaDOTS",
    desc: [
      "Created an algorithm using Pandas and Python to parse UMD's Department of Transportation's rules and regulations to provide users with places they are allowed to park given input ",
    ],
  },
  {
    imag: p1,
    link: "https://github.com/holland-cw3/KingOfTheTub",
    title: "King of the Tub",
    desc: [
      "• Scripted with Unity’s .NET framework, applying OOP principles with C# to create a mobile application.",
      "• Developed the UI, an XOR data encryption algorithm, and a Cloud Saving system by serializing a dictionary to JSON.",
      "• Spearheaded a diverse team of 5 students to work on Music, User Interface, and Gameplay Mechanics.",
      "• Drafted 200+ mock-ups of artwork for characters, objects, and animations using Adobe Illustrator and Photoshop.",
    ],
  },
  {
    imag: p4,
    link: "https://github.com/UMDWebmaster/clubrunningumd/",
    title: "UMD Club Running Website",
    desc: [
      "• Overhauled a cPanel/PHP website to use JavaScript, React.js, Firebase, and Tailwind CSS.",
      "• Orchestrated cost-effective domain and hosting solutions, reducing spending on the website by 93%.",
      "• Used Google Search Console to improve Search Engine Optimization by 70%, attracting 27400+ impressions in 12 months.",
      "• Designed an admin-only data management system with Firebase to manage 4000+ resources, increasing scalability.",
    ],
  },
  {
    imag: p3,
    link: "https://cdaily.co/",
    title: "CDaily",
    desc: [
      "•	Created a scraper for Certificate of Deposit (CD) rates from 10+ public banks, using JavaScript, Node.js, and Puppeteer.js.",
      "•	Pioneered a Chrome extension using React.js and Tailwind CSS, formatting data into a searchable table.",
    ],
  },
  {
    imag: p2,
    link: "https://github.com/holland-cw3/Strava-Hot-Takes",
    title: "Strava Hot Takes",
    desc: [
      "• JavaScript automation script that uses Node.js webhooks and the Strava API, saving 30+ minutes per week.",
      "• Updates my most recent running activity with a random joke within 3ms of receiving a POST request.",
    ],
  },
];

function MediaCard(img, link, title, desc) {
  return (
    <Card sx={{ maxWidth: "100%" }}>
      <CardMedia sx={{ height: 250 }} image={img} title={title} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          <ul>
            {desc.map((x) => (
              <li>{x}</li>
            ))}
          </ul>
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">
          <a href={link}>Click to See Project!</a>
        </Button>
      </CardActions>
    </Card>
  );
}

export default function About() {
  return (
    <section id="about" class="mb-20">
      <div className="container mx-auto">
        <div className="mb-10 flex flex-col items-center pt-20">
          <h1 className="mb-5 text-5xl font-bold">Projects</h1>
          <hr class="w-full"></hr>
        </div>

        <div className="grid-container">
          {projects.map((x) => MediaCard(x.imag, x.link, x.title, x.desc))}
        </div>
      </div>
    </section>
  );
}
